// Make elementor wrapper clickable
jQuery(document).ready(function () {
    jQuery("body").on("click", ".click-child", function () {
        var href = jQuery(this).find("a").attr('href');
        window.location = href;
    });
});

jQuery("#filter-btn").click(function () {
    jQuery("#filters").slideToggle("slow");
});
